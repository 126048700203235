<template>
    <nav id="nav">
        <div class="content">
            <div class="logo" @click="mcdonaldsPage()"></div>
            <div class="text" v-if="isClientInformation">
                {{ $t('public.nav.text1') }} <b>{{ $t('public.nav.text2') }}</b>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Nav',
    computed: {
        step: {
            get: function () {
                return this.$store.getters['form/getStep']
            }
        },
        isClientInformation: {
            get: function () {
                return this.$store.getters['form/isClientInformation']
            }
        }
    },
    methods: {
        mcdonaldsPage: function () {
            window.open('https://mcdonalds.es/')
        }
    }
}
</script>

<style lang="scss">
#nav {
    height: 60px;
    display: flex;
    justify-items: center;
    align-items: center;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.35);

    .content {
        width: 100%;
        max-width: 1400px;
        display: block;
        margin: 0 auto;

        .logo {
            background-image: url('../../../public/img/logo.png');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            height: 40px;
            width: 40px;
            margin-left: 26px;
            cursor: pointer;
            float: left;
        }

        .text {
            height: 100%;
            color: $black;
            font-size: 20px;
            margin-left: 30px;
            width: fit-content;
            float: left;
            line-height: 40px;

            b {
                font-family: Speedee-bd;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .content {
            .logo {
                height: 30px;
                width: 30px;
                margin-left: 10px;
            }

            .text {
                margin-left: 10px;
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
}
</style>
