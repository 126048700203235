import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const Form = () => import('../views/Form.vue')
const DownloadTicket = () => import('../views/DownloadTicket.vue')
const DownloadCorrectiveTicket = () => import('../views/DownloadCorrectiveTicket.vue')
const Admin = () => import('../views/Admin.vue')
const AdminLogin = () => import('../views/AdminLogin')
const Bills = () => import('../views/Bills')
const Customers = () => import('../views/Customers')
const Restaurants = () => import('../views/Restaurants')
const Notifications = () => import('../views/Notifications')
const Access = () => import('../views/Access')
const RestaurantEdit = () => import('../views/RestaurantEdit')
const CorrectiveBills = () => import('../views/CorrectiveBills.vue')

const routes = [
    {
        path: '/',
        name: 'Form',
        meta: { layout: 'public-layout' },
        component: Form
    },
    {
        path: '/bill/:id',
        name: 'DownloadTicket',
        meta: { layout: 'download-layout' },
        component: DownloadTicket
    },
    {
        path: '/correctiveBill/:id',
        name: 'DownloadCorrectiveTicket',
        meta: { layout: 'download-layout' },
        component: DownloadCorrectiveTicket
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: { layout: 'admin-layout' },
        component: Admin,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/bills',
        meta: { navName: 'bills.title', layout: 'admin-layout' },
        name: 'Bills',
        component: Bills,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/customers',
        meta: { navName: 'customers.title', layout: 'admin-layout' },
        name: 'Customers',
        component: Customers,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/restaurants',
        meta: { navName: 'restaurants.title', layout: 'admin-layout' },
        name: 'Restaurants',
        component: Restaurants,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/restaurants/:id/edit',
        meta: { navName: 'restaurants.title_edit', layout: 'admin-layout' },
        name: 'RestaurantEdit',
        component: RestaurantEdit,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/notifications',
        meta: {
            navName: 'notification.title',
            layout: 'admin-layout'
        },
        name: 'Notifications',
        component: Notifications,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/access',
        meta: {
            navName: 'access.title',
            layout: 'admin-layout'
        },
        name: 'Access',
        component: Access,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/access/add',
        meta: {
            navName: 'access.title',
            layout: 'admin-layout'
        },
        name: 'AddAccess',
        component: Access,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/access/:id',
        meta: {
            navName: 'access.title',
            layout: 'admin-layout'
        },
        name: 'EditAccess',
        component: Access,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/login',
        name: 'Login',
        meta: {
            layout: 'download-layout'
        },
        component: AdminLogin
    },
    {
        path: '/admin/login/resetPassword/:id',
        name: 'ResetPassword',
        meta: {
            layout: 'download-layout'
        },
        component: AdminLogin
    },
    {
        path: '/miturnologin/:id1/:id2',
        name: 'MiTurnoLogin',
        meta: {
            layout: 'download-layout'
        },
        component: AdminLogin
    },
    {
        path: '/admin/corrective-bills',
        name: 'CorrectiveBills',
        meta: {
            navName: 'bills.corrective_bills',
            layout: 'admin-layout'
        },
        component: CorrectiveBills,
        beforeEnter: checkBeforeEnter
    }
]

async function checkBeforeEnter(to, from, next) {
    store
        .dispatch('validateToken')
        .then(() => {
            store.getters['isLogged'] ? next() : next('/admin/login')
        })
        .catch(() => {
            next('/admin/login')
        })
}

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
