import { Api } from '@/core/api.js'
const api = new Api()

const state = {
    step: 1,
    name: '',
    documentTypes: {
        1: 'CIF',
        2: 'NIE',
        3: 'NIF',
        4: 'Otro'
    },
    documentType: -1,
    document: '',
    fiscalAddress: '',
    email: '',
    tickets: [],
    ticketNumber: '',
    ticketDate: '',
    ticketPrice: '',
    isClientInformation: false,
    captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
    ticketsIds: []
}

const getters = {
    getStep: (state) => {
        return state.step
    },
    getName: (state) => {
        return state.name
    },
    getDocumentTypes: (state) => {
        return state.documentTypes
    },
    getDocumentType: (state) => {
        return state.documentType
    },
    getDocument: (state) => {
        return state.document
    },
    getFiscalAddress: (state) => {
        return state.fiscalAddress
    },
    getEmail: (state) => {
        return state.email
    },
    getTickets: (state) => {
        return state.tickets
    },
    getTicketNumber: (state) => {
        return state.ticketNumber
    },
    getTicketDate: (state) => {
        return state.ticketDate
    },
    getTicketPrice: (state) => {
        return state.ticketPrice
    },
    isClientInformation: (state) => {
        return state.isClientInformation
    },
    getCaptchaKey: (state) => {
        return state.captchaKey
    },
    getTicketsIds: (state) => {
        return state.ticketsIds
    }
}

const actions = {
    validateDocument(context) {
        let isValidDocument = true
        let documentArray = Array.from(context.state.document.toLowerCase())
        const documentType = parseInt(context.state.documentType)
        const lettersList = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        const numberList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        const document = context.state.document.trim()

        // filter special characters
        documentArray = documentArray.filter((word) => lettersList.includes(word.toLowerCase()) || numberList.includes(word.toLowerCase()))
        context.state.document = documentArray.join('').toUpperCase()

        if (documentArray.length != 9) {
            isValidDocument = false
        }

        switch (documentType) {
            // CIF
            // Un CIF debe tener 9 cifras. La primera cifra (Una letra), indica el tipo de sociedad
            // La ultima cifra es el dígito de control, que puede ser o bien un número o bien una letra, en función del tipo de sociedad
            case 1:
                {
                    if (!lettersList.includes(documentArray[0])) {
                        isValidDocument = false
                    }

                    for (let i = 1; i < 7; i++) {
                        if (!numberList.includes(documentArray[i])) {
                            isValidDocument = false
                        }
                    }

                    if (!lettersList.includes(documentArray[8]) && !numberList.includes(documentArray[8])) {
                        isValidDocument = false
                    }
                }

                break

            // NIE
            // El primer carácter del NIE debe ser una de últimas letras del abecedario ("X", "Y" o "Z") Letra + 7 números + letra
            case 2:
                {
                    if (!['x', 'y', 'z'].includes(documentArray[0])) {
                        isValidDocument = false
                    }

                    for (let i = 1; i < 7; i++) {
                        if (!numberList.includes(documentArray[i])) {
                            isValidDocument = false
                        }
                    }

                    if (!lettersList.includes(documentArray[8])) {
                        isValidDocument = false
                    }
                }
                break

            // NIF
            // Letra + 8 números ( ejemplo A28586097 ) o Letra + 7 números + letra
            case 3:
                {
                    for (let i = 0; i < 7; i++) {
                        if (!numberList.includes(documentArray[i])) {
                            isValidDocument = false
                        }
                    }

                    if (!lettersList.includes(documentArray[8])) {
                        isValidDocument = false
                    }

                    if (isValidDocument) {
                        const numericPart = parseInt(context.state.document.substring(0, 8))
                        const letter = context.state.document.substring(8, 9).toLowerCase()
                        const nifLetters = ['t', 'r', 'w', 'a', 'g', 'm', 'y', 'f', 'p', 'd', 'x', 'b', 'n', 'j', 'z', 's', 'q', 'v', 'h', 'l', 'c', 'k', 'e']
                        const resultLetter = nifLetters[numericPart % 23]

                        if (letter != resultLetter) {
                            isValidDocument = false
                        }
                    }
                }
                break
            case 4:
                isValidDocument = document.length > 0 && document.length <= 15
                break

            default:
                isValidDocument = false
                break
        }

        return isValidDocument
    },

    validateEmail(context) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regexp.test(String(context.state.email).toLowerCase())
    },

    validateTicket(context, params) {
        return api
            .post(`ticket/validate`, params)
            .then((response) => {
                if (response.status) {
                    context.commit('addTicketId', response.data.ticketId)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },

    addInvoice(context) {
        return api
            .post(`ticket/invoice/add`, { invoice_number: context.state.document, name: context.state.name, document_type: context.state.documentType, fiscal_address: context.state.fiscalAddress, tickets: context.state.ticketsIds.toString(), email: context.state.email })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    },

    downloadBill(context, params) {
        return api
            .get(`bill/${params.bill}/download`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    },
    downloadCorrectiveBill(context, params) {
        return api
            .get(`correctiveBill/${params.bill}/download`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    }
}

const mutations = {
    setStep: (state, value) => {
        state.step = value
    },
    setName: (state, value) => {
        state.name = value
        exitPage()
    },
    setDocumentType: (state, value) => {
        state.documentType = value
        exitPage()
    },
    setDocument: (state, value) => {
        state.document = value
        exitPage()
    },
    setFiscalAddress: (state, value) => {
        state.fiscalAddress = value
        exitPage()
    },
    setEmail: (state, value) => {
        state.email = value
        exitPage()
    },
    addTicket: (state, value) => {
        state.tickets.push(value)
    },
    setTickets: (state, value) => {
        state.tickets = value
    },
    setTicketNumber: (state, value) => {
        state.ticketNumber = value
    },
    setTicketDate: (state, value) => {
        state.ticketDate = value
    },
    setTicketPrice: (state, value) => {
        state.ticketPrice = value
    },
    setClientInformation: (state, value) => {
        state.isClientInformation = value
    },
    addTicketId: (state, value) => {
        state.ticketsIds.push(value)
    },
    setTicketsIds: (state, value) => {
        state.ticketsIds = value
    }
}

function exitPage() {
    window.addEventListener('beforeunload', (e) => {
        e.preventDefault()
        e.returnValue = ''
    })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
