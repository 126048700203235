<template>
    <div id="public-layout">
        <Nav></Nav>
        <router-view />
        <Footer></Footer>
    </div>
</template>

<script>
import Nav from '@/components/layout/Nav'
import Footer from '@/components/layout/Footer'

export default {
    name: 'Public-Layout',
    components: {
        Nav,
        Footer
    }
}
</script>

<style lang="scss"></style>
