<template>
    <div id="admin-layout">
        <Nav></Nav>
        <div class="content">
            <LeftMenu></LeftMenu>
            <router-view />
            <div class="copyright">
                <div class="made-with">{{ $t('intowin.text') }}</div>
                <span class="icon-heart"><img src="../../public/img/heart_copyright.svg" /></span>
                <div class="made-with">{{ $t('intowin.text2') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Nav from '@/components/layout/admin/Nav'
import LeftMenu from '@/components/layout/admin/LeftMenu'
export default {
    name: 'Admin-Layout',
    components: {
        Nav,
        LeftMenu
    },
    methods: {
        load() {
            var userLogged = this.$store.getters['getUserLogged']
            if (Object.keys(userLogged).length == 0) {
                this.$store.dispatch('init')
            }
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
#admin-layout {
    padding: 0 26px;

    .content {
        padding: 0px 0px;
        display: flex;
        justify-content: flex-start;
        height: 100%;
        max-height: calc(100% - 103px);
        position: absolute;
        overflow: hidden;
        width: 100%;
        padding-bottom: 26px;

        .copyright {
            display: flex;
            position: absolute;
            bottom: 0px;
            right: 70px;
            font-family: Speedee;
            color: #949494;
            font-size: 11px;

            .icon-heart {
                display: block;
                width: 18px;
                height: 18px;
                padding-left: 4px;
            }
        }
    }
}
</style>
