import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    access: {},
    newAccess: {
        name: '',
        type: 3,
        email: '',
        restaurants: []
    }
}

const getters = {
    getAccess: (state) => (id) => {
        if (id) {
            return state.access[id]
        }
        return state.access
    }
}

const actions = {
    loadAccess(context) {
        return api
            .get(`admin/user/list`)
            .then((response) => {
                if (response.status) {
                    context.commit('setAccess', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    changePassword(context, params) {
        return api
            .post(`admin/user/changePassword`, params)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    },
    editAccess(context, params) {
        var user = context.getters['getAccess'](params)
        user.username = user.email
        user.add = 0
        return api.post('admin/user/save', user).then(function (response) {
            if (response.status) {
                // response.data.locations = Object.values(response.data.locations)
                context.commit('updateAccess', response.data)
            } else {
                // console.log(response.status);
            }
        })
    },
    addAccess(context, params) {
        var user = context.getters['getAccess'](params)
        delete user['id']
        user.username = user.email
        user.add = 1
        return api.post('admin/user/save', user).then(function (response) {
            if (response.status) {
                // response.data.locations = Object.values(response.data.locations)
                context.commit('updateAccess', response.data)
            } else {
                // console.log(response.status);
            }
        })
    },
    addNewAccess(context) {
        var access = Object.assign({}, context.state.newAccess)
        access.id = 'tmpitw'
        context.commit('createNewAccess', access)
        return access.id
    },
    validateUser(context, params) {
        var userToValidate = context.getters['getAccess'](params)
        var errors = {
            name: false,
            email: false,
            type: false,
            restaurants: false
        }
        const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var regexp = new RegExp('(@es.mcd.com)')

        var sendForm = true
        Object.keys(errors).forEach((v) => (errors[v] = false))

        if (userToValidate.name === '') {
            sendForm = false
            errors.name = 'access.error_empty_name'
        }

        if (userToValidate.type == '') {
            sendForm = false
            errors.type = true
        }

        if (!emailValidation.test(userToValidate.email.toLowerCase())) {
            sendForm = false
            errors.email = 'access.error_no_valid_email'
        }

        if (userToValidate.email === '') {
            sendForm = false
            errors.email = 'access.error_empty_name'
        }

        if (!errors.email && userToValidate.type == 3 && !regexp.exec(userToValidate.email)) {
            sendForm = false
            errors.email = 'access.error_email_mcd'
        }

        if (!errors.email && userToValidate.type == 4 && regexp.exec(userToValidate.email)) {
            sendForm = false
            errors.email = 'access.error_email_not_mcd'
        }

        return {
            status: sendForm,
            errors: errors
        }
    },
    removeUser(context, params) {
        return api.get(`admin/user/${params.id}/delete`).then((response) => {
            return response
        })
    }
}

const mutations = {
    setAccess: (state, value) => {
        state.access = value
        Vue.set(state, 'access', value)
    },
    updateAccess(state, payload) {
        var access = { ...state.access }
        access[payload.id] = payload
        Vue.set(state, 'access', { ...access })
    },
    createNewAccess(state, payload) {
        var access = { ...state.access }
        access[payload.id] = payload
        Vue.set(state, 'access', { ...access })
    },
    deleteUser(state, params) {
        var access = state.access
        delete access[params]
        state.access = access
        Vue.set(state, 'access', { ...access })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
