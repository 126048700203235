<template>
    <footer id="footer">
        <div class="title">{{ $t('public.frequent_questions.title') }}</div>
        <div class="questions-container" id="faq">
            <Faq v-for="(faq, index) in faqs" :key="index" :question="faq.question" :answer="faq.answer"></Faq>
        </div>
        <div class="links-container">
            <div class="link" @click="openLegalLink">{{ $t('public.links.privacy_policy') }}</div>
        </div>
        <a href="https://intowin.io/" target="_blank" class="firm" v-html="$t('public.firm')"></a>
    </footer>
</template>

<script>
import Faq from '@/components/ui/Faq'
export default {
    name: 'Footer',
    components: {
        Faq
    },
    computed: {
        faqs: function () {
            return this.$store.getters['getFaqs']
        }
    },
    created() {
        this.$store.dispatch('loadFaqs')
    },
    methods: {
        openLegalLink(event) {
            event.preventDefault()
            event.stopPropagation()
            const url = `${process.env.VUE_APP_API_URL}static/files/politica-privacidad.pdf`
            window.open(url)
            // window.open(this.$t('public.links.privacy_policy_link'))
        }
    }
}
</script>

<style lang="scss">
#footer {
    margin-top: 140px;

    .title {
        color: $black;
        font-size: 44px;
        font-family: Speedee-bd;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 54px;
        text-align: center;
    }

    .questions-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .links-container {
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;

        .link {
            color: #949494;
            font-size: 14px;
            text-decoration-line: underline;
            cursor: pointer;
            width: 100%;
            margin: 0 auto 10px auto;
        }
    }

    .firm {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        color: #949494;
        font-size: 11px;
        margin-top: 54px;
        margin-bottom: 33px;
        text-decoration: none;
    }

    @media screen and (max-width: 720px) {
        margin-top: 40px;

        .title {
            font-size: 36px;
            padding: 0 25px;
        }

        .links-container {
            margin-top: 40px;
        }
    }
}
</style>
