<template>
    <div id="admin-nav">
        <div class="left">
            <div class="logo"></div>
            <div class="title">{{ $t('admin.login.title') }}</div>
            <div class="separator">|</div>
            <div class="page" v-if="navName">{{ $t(navName) }}</div>
        </div>
        <div class="right">
            <div class="user-container" :class="{ selected: userMenuOpen }" @click="userMenuOpen = !userMenuOpen">
                <div class="name" v-if="user">{{ user.name }}</div>
                <div class="ball" v-if="user.name">{{ user.name.charAt(0) }}</div>
                <transition name="fade">
                    <div class="submenu-user" v-if="userMenuOpen">
                        <div class="text-logout" @click="logout()">{{ $t('admin.menu_top.disconnect') }}</div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminNav',
    data() {
        return {
            userMenuOpen: false
        }
    },
    computed: {
        navName() {
            return this.$route.meta.navName
        },
        user() {
            return this.$store.getters['getUserLogged']
        }
    },
    methods: {
        logout() {
            this.$router.push({ name: 'Login' })
            location.reload()
        }
    }
}
</script>

<style lang="scss">
#admin-nav {
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 50%;

        .logo {
            background-image: url('../../../../public/img/logo.png');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            height: 40px;
            width: 40px;
        }

        .title {
            margin-left: 20px;
            font-size: 30px;
            color: #777;
            font-family: Speedee-lt !important;
            font-weight: lighter !important;
        }

        .separator {
            font-size: 30px;
            color: #ccc;
            font-family: Speedee;
            margin: 0 8px;
        }

        .page {
            font-size: 24px;
            color: #222;
            font-family: Speedee-bd;
        }
    }

    .right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .user-container {
            background-image: url('../../../../public/img/arrow_down.svg');
            background-size: 12px;
            background-position: right 5px center;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 6px 6px;
            padding-right: 30px;
            border-radius: 4px;
            position: relative;

            &.selected {
                background-image: url('../../../../public/img/arrow_up.png');
                background-color: #f5f4f4;
            }

            .submenu-user {
                position: absolute;
                background: #ffffff;
                box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                top: 80px;
                right: 0px;
                z-index: 3;

                .text-logout {
                    background-image: url('../../../../public/img/logout.svg');
                    background-position: right 5px center;
                    background-size: 20px;
                    padding: 10px;
                    padding-right: 40px;
                    width: auto;

                    &:hover {
                        border-radius: 4px;
                        background-color: rgb(243, 243, 243);
                    }
                }
            }
        }

        .name {
            font-size: 18px;
            color: #222;
            font-family: Speedee;
            margin-right: 20px;
        }

        .ball {
            background-color: #ccc;
            font-size: 24px;
            color: #222;
            font-family: Speedee-bd;
            border-radius: 50%;
            padding: 10px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
        }
    }
}
</style>
