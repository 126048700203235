import Vue from 'vue'
import Vuex from 'vuex'
import { Api } from '@/core/api.js'

import form from './modules/form'
import bills from './modules/bills'
import customers from './modules/customers'
import restaurant from './modules/restaurant'
import notifications from './modules/notifications'
import access from './modules/access'

Vue.use(Vuex)

const api = new Api()

export default new Vuex.Store({
    state: {
        faqs: {},
        isLogged: false,
        token: false,
        userLogged: false
    },
    getters: {
        getFaqs: (state) => {
            return state.faqs
        },
        isLogged: (state) => {
            return state.isLogged
        },
        getToken: (state) => {
            return state.token
        },
        getUserLogged: (state) => {
            return state.userLogged
        }
    },
    actions: {
        loadFaqs: (context) => {
            return api.get('faq/list').then((response) => {
                if (response.status) {
                    return context.commit('setFaqs', response.data)
                }
            })
        },
        login: (context, params) => {
            return api
                .post('admin/login', { user: params.user, password: params.password })
                .then((response) => {
                    if (response.status) {
                        context.commit('setLogged', true)
                    }

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        rememberPassword(context, params) {
            return api
                .post('admin/user/resetPassword', params)
                .then((response) => {
                    if (response.status) {
                        return response
                    }

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        init: (context) => {
            return api
                .get('admin/init')
                .then((response) => {
                    if (response.status) {
                        context.commit('setUserLogged', response.data)
                    }
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        validateToken: (context) => {
            const token = localStorage.getItem('token')

            if (typeof token !== 'undefined' && token) {
                context.commit('setToken', token)
                context.commit('setLogged', true)
            }
        },
        miTurnoLogin: (context, params) => {
            return api
                .post('admin/login/miturno', params)
                .then((response) => {
                    if (response.status) {
                        return response
                    }

                    return response
                })
                .catch((error) => {
                    return error
                })
        }
    },
    mutations: {
        setFaqs: (state, value) => {
            state.faqs = value
        },
        setLogged: (state, value) => {
            state.isLogged = value
        },
        setToken: (state, value) => {
            state.token = value
            localStorage.setItem('token', value)
        },
        setUserLogged: (state, value) => {
            state.userLogged = value
        }
    },
    modules: { form, bills, restaurant, customers, notifications, access }
})
