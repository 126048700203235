import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    bills: {},
    correctiveBills: {},
    billsFilters: {},
    billsFiltersActive: {
        date: 'month',
        restaurants: 'admin.filters.all_restaurants',
        customers: 'admin.filters.all_customers'
    }
}

const getters = {
    getBills: (state) => (id) => {
        if (id) {
            return state.bills[id]
        }
        return state.bills
    },
    getCorrectiveBills: (state) => {
        return state.correctiveBills
    },
    getBillsFilters: (state) => {
        return state.billsFilters
    },
    getBillsFiltersActive: (state) => {
        return state.billsFiltersActive
    }
}

const actions = {
    loadBills(context, params) {
        return api
            .post(`admin/bill/list`, params)
            .then((response) => {
                if (response.status) {
                    context.commit('setBills', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    async loadCorrectiveBills(context, params) {
        try {
            const { data } = await api.post('admin/corrective-bills/list', params)
            context.commit('setCorrectiveBills', data)
        } catch (error) {
            return error
        }
    },
    downloadPDF(context, params) {
        return api
            .post(`admin/bill/list/download/pdf`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadEXCEL(context, params) {
        return api
            .post(`admin/bill/list/download/excel`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadXML(context, params) {
        return api
            .post(`admin/bill/list/download/xml`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    loadFiltersBills(context) {
        return api
            .get(`admin/bill/filters`)
            .then((response) => {
                if (response.status) {
                    context.commit('setBillsFilters', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    async resendBill(context, { invoiceNumber }) {
        try {
            const response = api.get(`admin/bill/resend/${invoiceNumber}`)
            return response.status
        } catch (error) {
            return error
        }
    },
    async regenerateBill(context, { invoiceNumber }) {
        try {
            const response = await api.get(`admin/bill/regenerate/${invoiceNumber}`)
            context.commit('updateFile', { invoice: invoiceNumber, file: response.data })
            return response.data
        } catch (error) {
            return error
        }
    },
    async cancelBill(context, { invoiceNumber }) {
        try {
            const response = await api.get(`admin/bill/cancel/${invoiceNumber}`)
            context.commit('updateStatus', { invoice: invoiceNumber, status: response.data.status })
            return response.data
        } catch (error) {
            return error
        }
    }
}

const mutations = {
    setBills: (state, value) => {
        state.bills = value
        Vue.set(state, 'bills', value)
    },
    setCorrectiveBills: (state, value) => {
        state.correctiveBills = value
        Vue.set(state, 'correctiveBills', value)
    },
    setBillsFilters: (state, value) => {
        state.billsFilters = value
        Vue.set(state, 'billsFilters', value)
    },
    setBillsFiltersActive: (state, value) => {
        state.billsFiltersActive = value
        Vue.set(state, 'billsFiltersActive', value)
    },
    updateFile(state, { invoice, file }) {
        state.bills[invoice].file = file
        console.error(state.bills)
    },
    updateStatus(state, { invoice, status }) {
        state.bills[invoice].status = status
        Vue.set(state, 'bills', state.bills)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
