<template>
    <v-app id="app">
        <component :is="layout">
            <router-view />
        </component>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    computed: {
        layout() {
            let layout = ''
            switch (this.$route.meta.layout) {
                case 'public-layout':
                    layout = 'public-layout'
                    break
                case 'download-layout':
                    layout = 'download-layout'
                    break
                case 'admin-layout':
                    layout = 'admin-layout'
                    break
            }
            return layout
        }
    },
    mounted() {}
}
</script>

<style lang="scss"></style>
