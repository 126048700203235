import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    restaurants: {}
}

const getters = {
    getRestaurants: (state) => (id) => {
        if (id) {
            return state.restaurants[id]
        }
        return state.restaurants
    }
}

const actions = {
    loadRestaurants(context) {
        return api
            .get(`admin/restaurant/list`)
            .then((response) => {
                if (response.status) {
                    context.commit('setRestaurants', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    edit(context, params) {
        return api
            .post(`admin/restaurant/edit`, { id: params.id, alternative_address: params.alternative_address })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    },
    async downloadTermsAndConditions() {
        try {
            const response = await api.get(`admin/terms-and-conditions`)
            return response.data
        } catch (error) {
            return error
        }
    },
    async activateAutofactura(context, { restaurantId }) {
        try {
            await api.get(`admin/miturno/activate-restaurant/${restaurantId}`)
            context.commit('activateRestaurant', { restaurantId })
            return true
        } catch (error) {
            return error
        }
    }
}

const mutations = {
    setRestaurants: (state, value) => {
        state.restaurants = value
        Vue.set(state, 'restaurants', value)
    },
    activateRestaurant: (state, { restaurantId }) => {
        state.restaurants[restaurantId].status = 1
        Vue.set(state, 'restaurants', state.restaurants)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
