<template>
    <div class="question-element" @click="answerVisible = !answerVisible">
        <div class="question" :class="{ hide: !answerVisible }">{{ question }}</div>
        <div class="response" :class="{ hide: !answerVisible }" v-html="answer"></div>
    </div>
</template>

<script>
export default {
    name: 'FaQ',
    props: {
        question: String,
        answer: String
    },
    data() {
        return {
            answerVisible: false
        }
    }
}
</script>

<style lang="scss">
.question-element {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 23px;
    cursor: pointer;
    padding: 0 40px;
    width: 80%;

    .question {
        color: $black;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
        width: auto;

        &::after {
            content: ' ';
            width: 12px;
            height: 12px;
            margin-left: 10px;
            display: inline-block;
            background-image: url('../../../public/img/arrow.png');
            background-size: 12px;
            background-position: center right;
            background-repeat: no-repeat;
        }

        &.hide {
            &::after {
                background-image: url('../../../public/img/arrow_up.png');
            }
        }
    }

    .response {
        color: $black;
        font-size: 16px;
        width: 100%;

        &.hide {
            display: none;
        }

        .image {
            display: inline-block;
            height: 350px;
            width: 250px;
            background-size: 100%;
            background-position: center right;
            background-repeat: no-repeat;
            margin: 20px 50px 0 0;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.35);

            &.image1 {
                background-image: url('../../../public/img/tooltip_ticket.jpg');
            }

            &.image2 {
                background-image: url('../../../public/img/tooltip_fecha.jpg');
            }

            &.image3 {
                background-image: url('../../../public/img/tooltip_importe.jpg');
            }
        }
    }

    @media screen and (max-width: 720px) {
        padding: 0 25px;
        width: 100%;
    }
}
</style>
