import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    customers: {},
    customersFilters: {},
    customersFiltersActive: {
        date: 'month',
        customers: 'admin.filters.all_customers'
    }
}

const getters = {
    getCustomers: (state) => (id) => {
        if (id) {
            return state.customers[id]
        }
        return state.customers
    },
    getCustomersFilters: (state) => {
        return state.customersFilters
    },
    getCustomersFiltersActive: (state) => {
        return state.customersFiltersActive
    }
}

const actions = {
    loadCustomers(context, params) {
        return api
            .post(`admin/client/list`, params)
            .then((response) => {
                if (response.status) {
                    context.commit('setCustomers', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    downloadPDF(context, params) {
        return api
            .post(`admin/client/list/download/pdf`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadEXCEL(context, params) {
        return api
            .post(`admin/client/list/download/excel`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadXML(context, params) {
        return api
            .post(`admin/client/list/download/xml `, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    loadFiltersCustomers(context) {
        return api
            .get(`admin/client/filters`)
            .then((response) => {
                if (response.status) {
                    context.commit('setCustomersFilters', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    }
}

const mutations = {
    setCustomers: (state, value) => {
        state.customers = value
        Vue.set(state, 'customers', value)
    },
    setCustomersFilters: (state, value) => {
        state.customersFilters = value
        Vue.set(state, 'customersFilters', value)
    },
    setCustomersFiltersActive: (state, value) => {
        state.customersFiltersActive = value
        Vue.set(state, 'customersFiltersActive', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
